export const LANG = {
  TC: 'TC',
  EN: 'EN',
  SC: 'SC',
  TC_TB: 'TC_TB',
  EN_TB: 'EN_TB',
  SC_TB: 'SC_TB',
  TC_MTR_TAXI: 'TC_MTR_TAXI',
  EN_MTR_TAXI: 'EN_MTR_TAXI',
  SC_MTR_TAXI: 'SC_MTR_TAXI',
  TC_COUNTER: 'TC_COUNTER',
  EN_COUNTER: 'EN_COUNTER',
  SC_COUNTER: 'SC_COUNTER',
  TC_HIGH_SPEED_RAIL: 'TC_HIGH_SPEED_RAIL',
  SC_HIGH_SPEED_RAIL: 'SC_HIGH_SPEED_RAIL',
  EN_HIGH_SPEED_RAIL: 'EN_HIGH_SPEED_RAIL',
  EN_AIRLINE: 'EN_AIRLINE',
  SC_AIRLINE: 'SC_AIRLINE',
  TC_AIRLINE: 'TC_AIRLINE',
};

export const MQTT = {
  VERSION: 'THEGULU_V2',
  CHANNEL: 'CLIENT',
  QUEUE: 'QUEUE',
  TICKET: 'TICKET',
  RESERVATION: 'RESERVATION',
  PICKUP: 'PICKUP',
  GENERAL: 'GENERAL',
};
export const MQTT_MSG_TYPE = {
  PICKUP_ADD: 'ADD_PICKUP_TAG',
  PICKUP_REMOVE: 'REMOVE_PICKUP_TAG',
  PICKUP_REMOVE_ALL: 'REMOVE_ALL_PICKUP_TAG',
  TOPIC_ALIVE: 'TOPIC_ALIVE',
  // TAG_BATCH: 'TAG_BATCH',
  TICKET: 'UPDATE_TICKET_SEQUENCE',
  TAG: 'ROLL_TAG',
  SPEAK: 'SPEAK',
  TO_QUEUE_TICKET: 'TO_QUEUE_TICKET',
  SUBSTITUTE: 'SUBSTITUTE',
  CHECK_IN: 'CHECK_IN',
  CHECK_OUT: 'CHECK_OUT',
  DELETE_TICKET: 'DELETE_TICKET',
  TRANSFER: 'TRANSFER',
  REJECT_TICKET: 'REJECT_TICKET',
  TAG_BATCH: 'TAG_BATCH',
  UPDATE_PICKUP_TICKET: 'UPDATE_PICKUP_TICKET',
};
export const MQTT_RESET_TIME = 10000;

export const MQTT_OPTIONS = {
  clientId: 'event-web-client-',
  username: `${process.env.REACT_APP_MQTT_USERNAME}`,
  password: `${process.env.REACT_APP_MQTT_PASSWORD}`,
  keepalive: 60 * 1000,
  protocolId: 'MQTT',
  protocolVersion: 4,
  clean: true,
  reconnectPeriod: 1000,
  connectTimeout: 30 * 1000,
  // resubscribe: true
};

export const QUEUE_STEPS = {
  CURRENT_SECTION: 'CURRENT_SECTION',
  SIZE: 'SIZE',
  TIME_SECTION: 'TIME_SECTION',
  TICKET_TYPE: 'TICKET_TYPE',
  TABLE_TYPE: 'TABLE_TYPE',
  DATE: 'DATE',
  TAGS: 'TAGS',
  PERSONAL_DATA: 'PERSONAL_DATA',
  PREVIEW: 'PREVIEW',
};

export const PERSONAL_DATAS = {
  EMAIL: 'EMAIL',
  MOBILE: 'MOBILE',
  COUNTRY_CODE: 'COUNTRY_CODE',
  TAC: 'TAC',
};

export const TICKET_DETAIL = {
  TABLE_SIZE: 'tableSize',
  TIME_SECTION: 'timeSectionLabel',
  TICKET_TYPE: 'ticketTypeLabel',
  TABLE_TYPE: 'tableTypeLabel',
  DATE: 'startTimestamp',
  TAGS: 'selectTagList',
  PERSONAL_DATA: 'PERSONAL_DATA',
};

export const DELIVERY_TYPE = {
  EMAIL: 'EMAIL',
  SMS: 'SMS',
  NONE: 'NONE',
  WHATSAPP: 'WHATSAPP',
};

export const STATUS = {
  ACTIVE: 'A',
  SUCCESS: 'S',
  DEACTIVE: 'D',
  REJECT: 'R',
  PENDING: 'P',
  WAITING: 'W',
  HOLD: 'H',
};

export const NAVIGATION = {
  PRE_REGISTRATION: 'PRE_REGISTRATION',
  MOBILE_QUEUE: 'MOBILE_QUEUE',
};

export const ELEMENT = {
  PROMOTION_TNC: 'PROMOTION_TNC',
  GULU_LOGO: 'GULU_LOGO',
  SITE_LIST_LOGO: 'SITE_LIST_LOGO',
  SITE_DETAIL_SITE_INFO: 'SITE_DETAIL_SITE_INFO',
  SITE_DETAIL_SITE_BANNER: 'SITE_DETAIL_SITE_BANNER',
  SITE_DETAIL_QUEUE_TITLE: 'SITE_DETAIL_QUEUE_TITLE',
  SITE_DETAIL_BANNER_LIST: 'SITE_DETAIL_BANNER_LIST',
  SITE_DETAIL_NAVIGATION_LIST_TOP: 'SITE_DETAIL_NAVIGATION_LIST_TOP',
  SITE_DETAIL_NAVIGATION_LIST_BOTTOM: 'SITE_DETAIL_NAVIGATION_LIST_BOTTOM',
  SITE_DETAIL_DETAIL_BOX: 'SITE_DETAIL_DETAIL_BOX',
  SITE_DETAIL_BOTTOM_BUTTON: 'SITE_DETAIL_BOTTOM_BUTTON',
  SITE_DETAIL_BOTTOM_BUTTON_ICON: 'SITE_DETAIL_BOTTOM_BUTTON_ICON',
  SITE_DETAIL_NAV_PRE_REG_ICON: 'SITE_DETAIL_NAV_PRE_REG_ICON',
  SITE_DETAIL_NAV_QRCODE_ICON: 'SITE_DETAIL_NAV_QRCODE_ICON',
  SITE_DETAIL_MOBILE_QUEUE: 'SITE_DETAIL_MOBILE_QUEUE',
  SITE_DETAIL_TAG_SEQUENCE_LIST: 'SITE_DETAIL_TAG_SEQUENCE_LIST',
  SITE_DETAIL_DESCRIPTION_LIST: 'SITE_DETAIL_DESCRIPTION_LIST',
  SITE_DETAIL_DATE: 'SITE_DETAIL_DATE',
  SITE_DETAIL_COUNTER_TAG_LIST: 'SITE_DETAIL_COUNTER_TAG_LIST',
  SITE_DETAIL_TAG_LIST_TITLE: 'SITE_DETAIL_TAG_LIST_TITLE',
  SITE_INFO_SITE_LOGO: 'SITE_INFO_SITE_LOGO',
  TAG_SEQUENCE_CALL_LOGO: 'TAG_SEQUENCE_CALL_LOGO',
  TAG_SEQUENCE_QUOTA: 'TAG_SEQUENCE_QUOTA',
  TAG_MOBILE_ICON: 'TAG_MOBILE_ICON',
  TICKET_DETAIL_TAG_SEQUENCE_LIST: 'TICKET_DETAIL_TAG_SEQUENCE_LIST',
  TICKET_DETAIL_COUNTER_TAG_LIST: 'TICKET_DETAIL_COUNTER_TAG_LIST',
  TICKET_DETAIL_BGCOLOR: 'TICKET_DETAIL_BGCOLOR',
  TICKET_DETAIL_EMAIL_FIELD: 'TICKET_DETAIL_EMAIL_FIELD',
  TICKET_DETAIL_POP_UP: 'TICKET_DETAIL_POP_UP',
  TICKET_DETAIL_SITE_INFO: 'TICKET_DETAIL_SITE_INFO',
  TV_TICKET_ICON: 'TV_TICKET_ICON',
  TV_TABLE_TYPE: 'TV_TABLE_TYPE',
  TAG_SEQUENCE_TIME: 'TAG_SEQUENCE_TIME',
  TAG_SEQUENCE_YOUR_TICKET: 'TAG_SEQUENCE_YOUR_TICKET',
  TAG_SEQUENCE_TABLE_TYPE: 'TAG_SEQUENCE_TABLE_TYPE',
};

export const PATH_LOCATION = {
  SITE_DETAIL: 'SITE_DETAIL',
  SITE_DETAIL_MAP: 'SITE_DETAIL_MAP',
  TICKET: 'TICKET',
};

export const COLOR = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
};
export const COUNTER_TAG_TYPE = {
  TAG_TO_COUNTER: 'TAG_TO_COUNTER',
  COUNTER_TO_TAG: 'COUNTER_TO_TAG',
};

export const LOCAL_STORAGE = {
  TICKET_HISTORY: 'TICKET_HISTORY',
};

export const TICKET_DISPLAY_TYPE = {
  NORMAL: 'NORMAL',
  EVENT: 'EVENT',
  BATCH: 'BATCH',
  RESERVATION: 'RESERVATION',
  TRANSFERABLE: 'TRANSFERABLE',
};

export const NOTIFIER = {
  EMAIL: 'EMAIL',
  WHATSAPP: 'WHATSAPP',
  SMS: 'SMS',
};

export const SURVEY_QUESTION_TYPE = {
  RADIO: 'RADIO',
  CHECKBOX: 'CHECKBOX',
  TEXT_FIELD: 'TEXT_FIELD',
  DROPDOWN: 'DROPDOWN',
  TEXT_AREA: 'TEXT_AREA',
  RATING: 'RATING',
  IMAGELIST: 'IMAGELIST',
  DROPDOWN_TEXT_FIELD: 'DROPDOWN_TEXT_FIELD',
};

export const DROPDOWN_TEXT_FIELD_TYPE = {
  DROPDOWN: 'DROPDOWN',
  TEXT_FIELD: 'TEXT_FIELD',
};

export const OTP_TYPE = {
  EMAIL: 'EMAIL',
  SMS: 'SMS',
  WHATSAPP: 'WHATSAPP',
};

export const SERVICE_TYPE = {
  QUEUE: 'QUEUE',
  RESERVATION: 'RESERVE',
};

export const RESERVATION_MODE = {
  SIZE: 'SIZE',
  SERVICE: 'SERVICE',
  NO_SELECTION: 'NO_SELECTION',
  SERVICE_WITH_SIZE: 'SERVICE_WITH_SIZE',
};

export const DEFAULT_TABLE_TYPE_COLOR = '#FD599A';