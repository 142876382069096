import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Helmet } from 'react-helmet';

import { API_URLS } from '../constants/apiUrls';
import { getOne } from '../utils/baseFetch';
import { setSite, setRejectReasonMessage } from '../reducers/siteSlice';
import { setQueueConfig, setTagSequenceList, setCounterTagList, setCounterList } from '../reducers/queueSlice';
import { subscribeMqttTopic, unsubscribeMqttTopic } from '../reducers/systemSlice';
import { resetTicket } from '../reducers/ticketSlice';
import { getTagLabel } from '../utils/queueUtils';
import { getStringByTheme, showElementByTheme } from '../utils/utils';
import { PATH } from '../constants/paths';
import { DEFAULT_TABLE_TYPE_COLOR, ELEMENT, LANG, MQTT } from '../constants/constants';

import { ReactComponent as LeftArrowIcon } from '../images/tv/mtr-left.svg';
import { ReactComponent as RightArrowIcon } from '../images/tv/mtr-right.svg';

import '../styles/site-detail.scss';
import '../styles/themes/airport-taxi.scss';
import { TVTagSequence } from '../components/TVTagSequence';
import { LanguageWrapper } from '../components/LanguageWrapper';
import { THEMES } from '../constants/themes';

export const TV = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const store = useStore();
  const { search } = useLocation();

  const site = useSelector((state) => state.site.site);
  const rejectReasonMessage = useSelector((state) => state.site.rejectReasonMessage);
  const tagSequenceList = useSelector((state) => state.queue.tagSequenceList);
  const lang = useSelector((state) => state.system.lang);
  const token = useSelector((state) => state.system.token);
  const theme = useSelector((state) => state.system.theme);

  // const [expandTagSequenceList, setExpandTagSequenceList] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showQueueServiceTempUnavailable, setShowQueueServiceTempUnavailable] = useState(false);
  const [renderSequence, setRenderSequence] = useState([]);
  const [tagSequenceMap, setTagSequenceMap] = useState(new Map());
  const [tableTypeColorList, setTableTypeColorList] = useState([]);
  const [extraTagList, setExtraTagList] = useState([]);
  const [tableTypeIconListMap, setTableTypeIconListMap] = useState(new Map());
  const [extraColumnList, setExtraColumnList] = useState([]);
  const [extraColumnMap, setExtraColumnMap] = useState(new Map());
  const [extraFillRow, setExtraFillRow] = useState(0);
  const [extraRowHeight, setExtraRowHeight] = useState(0);
  const [isVerticalView, setIsVerticalView] = useState(true);
  const tvWrapperRef = useRef(null);
  const tvBodyRef = useRef(null);

  useEffect(() => {
    return function unsubscribeMqtt() {
      tagSequenceList?.forEach((tagSequence) => {
        dispatch(unsubscribeMqttTopic([MQTT.VERSION, params.siteId, MQTT.CHANNEL, MQTT.QUEUE, tagSequence.timeSessionId, tagSequence.tableType].join('/')));
      });
    };
  }, []);

  const getTableTypeIconListMap = (tableTypeIconList) => {
    const tableTypeIconListMap = new Map();
    for (const item of tableTypeIconList) {
      for (const tableType of item.tableTypeList) {
        tableTypeIconListMap.set(tableType, {iconUrl: item.iconUrl, backgroundColor: item.backgroundColor});
      }
    }
    return tableTypeIconListMap;
  };

  const getExtraColumnListMap = (extraColumnList) => {
    const extraColumnListMap = new Map();
    for (const column of extraColumnList) {
      for (const item of column.itemList) {
        for (const tableType of item.tableTypeList) {
          extraColumnListMap.set(tableType, extraColumnListMap.get(tableType) ? [...extraColumnListMap.get(tableType), item.value] : [item.value]);
        }
      }
    }
    return extraColumnListMap;
  };

  useEffect(() => {
    const tagSequenceMap = new Map([
      ...tagSequenceList?.map((i) => [i.tableType, { ...i, tableTypeForegroundColor: i.tableTypeForegroundColor || DEFAULT_TABLE_TYPE_COLOR }]),
      ...extraTagList?.map((i) => [i.tableType, { ...i, tableTypeForegroundColor: i.tableTypeForegroundColor || DEFAULT_TABLE_TYPE_COLOR }]),
    ]);
    setTagSequenceMap(tagSequenceMap);
    const displaySequence = getRenderSequence(search, tagSequenceMap);
    setRenderSequence([...displaySequence]);
  }, [tagSequenceList, extraTagList]);

  useEffect(() => {
    document.title = getStringByTheme('siteTitle', lang, theme);
  }, [lang]);

  useEffect(() => {
    const searchParams = new URL(document.location).searchParams;
    if (!token && searchParams.get('qrcode')) {
      window.location = 'thegulu://web?action=' + process.env.PUBLIC_URL + PATH.SITE_DETAIL + params.siteId + '&type=CAMPAIGN';
    }
  }, [params.siteId]);

  const getTableTypeList = () => {
    const queryString = search.substring(1);
    const queryArray = queryString.split('&');
    const tableTypeList = [];

    if (queryArray) {
      for (const item of queryArray) {
        const [tableTypePart, direction] = item.split('=');

        if (!tableTypePart) {
          continue;
        }

        if(tableTypePart === 'orientation' && direction === 'H') {
          setIsVerticalView(false);
          continue;
        }

        const tableTypeArray = tableTypePart.split(',');

        for (const tableType of tableTypeArray) {
          tableTypeList.push(tableType);
        }
      }
    }

    return tableTypeList;
  };

  const modifyPayloadByTheme = (payload, theme) => {
    switch (theme) {
      case THEMES.AIRPORT_TAXI:
        return {
          ...payload,
          tagList: payload.tagList
            ? payload.tagList.map((tag) => ({
                ...tag,
                // ticketType: 'A',
                ticketTypeEnLabel: 'Taxi',
                ticketTypeTcLabel: '的士',
                ticketTypeForegroundColor: '#FFFFFF',
                ticketTypeBackgroundColor: '#0f083c',
              }))
            : null,
        };
      default:
        return payload;
    }
  };

  useEffect(() => {
    if (!lang) {
      return;
    }

    const newParams = {
      siteId: params.siteId,
    };

    const tableTypeList = getTableTypeList();

    if (tableTypeList.length > 0) {
      newParams.tableTypeList = tableTypeList;
    }

    getOne(
      API_URLS.GET_TV_DETAIL,
      newParams,
      (payload) => {
        if (payload.siteInfo?.name) {
          document.title = payload.siteInfo.name;
        }
        payload = modifyPayloadByTheme(payload, payload?.siteInfo?.theme);
        setTableTypeColorList(payload.tableTypeColorList ? payload.tableTypeColorList : []);
        dispatch(setQueueConfig(undefined));
        dispatch(resetTicket());
        dispatch(setSite(payload));
        dispatch(
          setTagSequenceList({
            siteId: params.siteId,
            tagSequenceList: payload.tagList ? payload.tagList : [],
          })
        );
        setExtraColumnList(payload.extraColumnList ? payload.extraColumnList : []);
        setExtraColumnMap(getExtraColumnListMap(payload.extraColumnList || []));
        setExtraTagList(payload.extraTagList ? payload.extraTagList : []);
        setTableTypeIconListMap(getTableTypeIconListMap(payload.tableTypeIconList ? payload.tableTypeIconList : []));
        const tempCounterTagList = payload.counterTagList ? payload.counterTagList : [];
        dispatch(
          setCounterTagList({
            siteId: params.siteId,
            counterTagList: tempCounterTagList,
          })
        );
        dispatch(
          setCounterList(
            payload.counterList
              ? payload.counterList.map((counter) => {
                  const tagList = tempCounterTagList
                    .filter((tag) => tag.counterName === counter)
                    .sort((tagA, tabB) => tabB.updateTimestamp - tagA.updateTimestamp);
                  const tempCounter = { counterName: counter };
                  if (tagList && tagList[0]) {
                    tempCounter.tagSequence = {
                      tableType: tagList[0].tableType,
                      tagLabel: getTagLabel(tagList[0].tableType, tagList[0].tagNumber, tagList[0].tagNumber),
                      counterName: tagList[0].counterName,
                    };
                    tempCounter.updateTimestamp = tagList[0].updateTimestamp;
                  }
                  return tempCounter;
                })
              : []
          )
        );
        if (payload.webRejectReasonDto) {
          if (payload.webRejectReasonDto.rejectReason) {
            dispatch(setRejectReasonMessage(payload.webRejectReasonDto.rejectReason));
            setShowQueueServiceTempUnavailable(false);
          }
        } else {
          dispatch(setRejectReasonMessage(''));
          setShowQueueServiceTempUnavailable(false);
        }

        if (payload.tagList) {
          payload.tagList.forEach((tagSequence) => {
            dispatch(subscribeMqttTopic([MQTT.VERSION, params.siteId, MQTT.CHANNEL, MQTT.QUEUE, tagSequence.timeSectionId, tagSequence.tableType].join('/')));
          });
        }

        setLoading(false);
      },
      undefined,
      store
    );
  }, [params.siteId, dispatch, store, lang]);

  const fillExtraRow = () => {
    const parentDiv = tvWrapperRef.current;
    const divContent = tvBodyRef.current;
    let fillHeight = 1;

    if (tvWrapperRef.current && tvBodyRef.current) {
      fillHeight = parentDiv.clientHeight - divContent.clientHeight;
    }

    if (fillHeight <= 50) {
      return;
    }

    const fillRow = Math.floor(fillHeight / 100);

    setExtraRowHeight(fillHeight / fillRow);
    setExtraFillRow(fillRow);
  };

  useEffect(() => {
    if (theme !== THEMES.HIGH_SPEED_RAIL && theme !== THEMES.AIRLINE) {
      return;
    }

    window.addEventListener('resize', fillExtraRow);
    return () => {
      window.removeEventListener('resize', fillExtraRow);
    };
  }, []);

  useEffect(() => {
    if (theme !== THEMES.HIGH_SPEED_RAIL && theme !== THEMES.AIRLINE) {
      return;
    }

    fillExtraRow();
  }, [tvWrapperRef.current, tvBodyRef.current]);
  // forbidden scroll
console.log(isVerticalView)
  return (
    <LanguageWrapper>
      {site && !loading && (
        <>
          <div className="tv-detail-wrapper" ref={tvWrapperRef}>
            <Helmet>
              <title>{site.name}</title>
              <meta name="description" content={site.name} />
              <meta property="og:title" content={site.name} />
              <meta property="og:description" content={site.name} />
            </Helmet>
            <div className="background-site-info-body" ref={tvBodyRef}>
              {rejectReasonMessage && <div className="reject-reason">{rejectReasonMessage}</div>}
              <table className={`tv-table ${!isVerticalView? 'horizontal' : ''}`}>
                <tbody>
                  {showElementByTheme(ELEMENT.SITE_DETAIL_TAG_SEQUENCE_LIST, theme, params.siteId) && renderSequence && renderSequence.length > 0 && (
                    <>
                      {renderSequence.map((section, idx) => (
                        <Fragment key={idx}>
                          <TagSequenceTicketType
                            extraColumnList={extraColumnList}
                            title={section.ticketType}
                            direction={section.direction}
                            bgColor={section.ticketTypeBackgroundColor}
                            textColor={section.ticketTypeTextColor}
                            label={{
                              en: section.ticketTypeEnLabel,
                              sc: section.ticketTypeScLabel,
                              tc: section.ticketTypeTcLabel,
                            }}
                            icon={section.ticketTypeIconUrl}
                          />
                          <TableTypeTitle
                            tagLabelList={section.tagList?.map((tag) => tagSequenceMap.get(tag)?.tagLabel)?.filter((item) => item !== undefined)}
                            extraColumnList={extraColumnList}
                            textColor={section.ticketTypeBackgroundColor}
                            ticketType={section.ticketType}
                          />
                          {section.tagList?.map(
                            (tag, idx) =>
                              tagSequenceMap.get(tag) && (
                                <TVTagSequence
                                  key={`${tag}-${tagSequenceMap.get(tag)?.tagLabel}-${idx}`}
                                  tagSequence={tagSequenceMap.get(tag) || {}}
                                  showQueueServiceTempUnavailable={showQueueServiceTempUnavailable}
                                  tableTypeColorList={tableTypeColorList}
                                  tableTypeIconListMap={tableTypeIconListMap}
                                  extraColumnMap={extraColumnMap}
                                  bgColor={idx % 2 !== 0}
                                />
                              )
                          )}
                        </Fragment>
                      ))}
                      {extraFillRow ? (
                        Array(extraFillRow)
                          ?.fill({})
                          .map((tag, idx) => (
                            <TVTagSequence
                              key={`${idx}`}
                              tagSequence={{}}
                              showQueueServiceTempUnavailable={showQueueServiceTempUnavailable}
                              tableTypeColorList={tableTypeColorList}
                              tableTypeIconListMap={tableTypeIconListMap}
                              extraColumnMap={extraColumnMap}
                              occupyAll
                              extraRowHeight={extraRowHeight}
                              bgColor={(renderSequence[renderSequence.length - 1]?.tagList?.length + idx) % 2 !== 0 ? '#d8f0c9' : null}
                            />
                          ))
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </LanguageWrapper>
  );
};

const getRenderSequence = (queryString, tagSequenceMap) => {
  const displayList = [];
  let tempSection = {};

  if (!queryString) {
    for (const [key, value] of tagSequenceMap) {
      tempSection.tagList = tempSection.tagList ? [...tempSection.tagList, key] : [key];
    }

    displayList.push(tempSection);

    return displayList;
  }

  queryString = queryString.substring(1);
  const queryArray = queryString.split('&');

  let prev = undefined;
  let prevDirection = '';

  for (const item of queryArray) {
    const [tableTypePart, direction] = item.split('=');
    const tableTypeArray = tableTypePart.split(',');

    for (const tableType of tableTypeArray) {
      const tagSequence = tagSequenceMap.get(tableType);

      if (prev === undefined) {
        if (!tagSequence) {
          continue;
        }
        tempSection.ticketType = tagSequence.ticketType;
        tempSection.ticketTypeEnLabel = tagSequence.ticketTypeEnLabel;
        tempSection.ticketTypeScLabel = tagSequence.ticketTypeScLabel;
        tempSection.ticketTypeTcLabel = tagSequence.ticketTypeTcLabel;
        tempSection.ticketTypeBackgroundColor = tagSequence.ticketTypeBackgroundColor || tagSequence.tableTypeBackgroundColor;
        tempSection.ticketTypeTextColor = tagSequence.ticketTypeForegroundColor || tagSequence.tableTypeForegroundColor;
        tempSection.ticketTypeIconUrl = tagSequence.ticketTypeIconUrl;
        tempSection.direction = direction;
        tempSection.tagList = [tableType];
        prevDirection = direction;
        prev = tagSequenceMap.get(tableType)?.ticketType;

        continue;
      }

      if (prev !== undefined && tagSequenceMap.get(tableType)?.ticketType !== prev) {
        if (!tagSequence) {
          continue;
        }

        displayList.push({ ...tempSection });

        prev = tagSequence.ticketType;
        tempSection = {};
        tempSection.ticketType = tagSequence.ticketType;
        tempSection.ticketTypeEnLabel = tagSequence.ticketTypeEnLabel;
        tempSection.ticketTypeScLabel = tagSequence.ticketTypeScLabel;
        tempSection.ticketTypeTcLabel = tagSequence.ticketTypeTcLabel;
        tempSection.ticketTypeBackgroundColor = tagSequence.ticketTypeBackgroundColor || tagSequence.tableTypeBackgroundColor;
        tempSection.ticketTypeTextColor = tagSequence.ticketTypeForegroundColor || tagSequence.tableTypeForegroundColor;
        tempSection.ticketTypeIconUrl = tagSequence.ticketTypeIconUrl;
        tempSection.direction = direction;
        tempSection.tagList = [tableType];
        prevDirection = direction;

        continue;
      }

      if (prevDirection !== direction) {
        if (!tagSequence) {
          continue;
        }

        displayList.push({ ...tempSection });

        prev = tagSequence.ticketType;
        tempSection = {};
        tempSection.ticketType = tagSequence.ticketType;
        tempSection.ticketTypeEnLabel = tagSequence.ticketTypeEnLabel;
        tempSection.ticketTypeScLabel = tagSequence.ticketTypeScLabel;
        tempSection.ticketTypeTcLabel = tagSequence.ticketTypeTcLabel;
        tempSection.ticketTypeBackgroundColor = tagSequence.ticketTypeBackgroundColor || tagSequence.tableTypeBackgroundColor;
        tempSection.ticketTypeTextColor = tagSequence.ticketTypeForegroundColor || tagSequence.tableTypeForegroundColor;
        tempSection.ticketTypeIconUrl = tagSequence.ticketTypeIconUrl;
        tempSection.direction = direction;
        tempSection.tagList = [tableType];
        prevDirection = direction;

        continue;
      }

      tempSection.tagList = [...tempSection.tagList, tableType];
    }
  }
  displayList.push({ ...tempSection });
  return displayList;
};

const TagSequenceTicketType = ({ extraColumnList, direction, bgColor, textColor = '#FFFFFF', label, icon }) => {
  // if(!title) {
  //   return <></>
  // }
  // const theme = useSelector((state) => state.system.theme);

  const style = {};

  if (bgColor) {
    style.background = bgColor;
  }

  if (textColor) {
    style.color = textColor;
  }

  const handleFullScreen = () => {
    document?.documentElement?.webkitRequestFullScreen();
  };

  switch (direction) {
    case 'R':
      return (
        <tr>
          <td colSpan={42}>
            <div
              className="ticket-type-title-wrapper"
              style={{ background: bgColor, color: textColor, justifyContent: 'end', textAlign: 'right' }}
              onClick={handleFullScreen}
            >
              <div className='ticket-title-and-icon'>
                <div className="title">
                  <div>{label.tc}</div>
                  {label.en && <div className="en">{label.en}</div>}
                </div>
                {icon && <img src={icon} alt="ticket-type-icon" className='right-table-type-icon'/>}

              </div>
              <RightArrowIcon className="right" />
            </div>
          </td>
        </tr>
      );
    case 'L':
      return (
        <tr>
          <td colSpan={42}>
            <div className="ticket-type-title-wrapper" style={{ background: bgColor, color: textColor }} onClick={handleFullScreen}>
              <LeftArrowIcon className="left" />
              <div className='ticket-title-and-icon'>
                {icon && <img src={icon} alt="ticket-type-icon" className='left-table-type-icon'/>}
                <div className="title">
                  <div>{label.tc}</div>
                  {label.en && <div className="en">{label.en}</div>}
                </div>
              </div>
            </div>
          </td>
        </tr>
      );
    default:
      return (
        <tr>
          <td colSpan={42}>
            <div className="ticket-type-title-wrapper no-direction" style={{ background: bgColor, color: textColor }} onClick={handleFullScreen}>
              <div className='ticket-title-and-icon'>
                {icon && <img src={icon} alt="ticket-type-icon" className='left-table-type-icon'/>}
                <div className="title">
                  <div>{label.tc}</div>
                  {label.en && <div className="en">{label.en}</div>}
                </div>
              </div>
            </div>
          </td>
        </tr>
      );
  }
};

const TableTypeTitle = ({ tagLabelList, extraColumnList, textColor, ticketType }) => {
  const theme = useSelector((state) => state.system.theme);
  return (
    <tr className={`tv-table-title ticket-type-${ticketType}`} style={{ color: textColor, borderBottom: `2px solid ${textColor}` }}>
      {showElementByTheme(ELEMENT.TV_TABLE_TYPE, theme) && <th></th>}
      <th>
        <div>{getStringByTheme('tableType', LANG.TC, theme)}</div>
        <div className="en">{getStringByTheme('tableType', LANG.EN, theme)}</div>
      </th>
      {extraColumnList?.map((column) => (
        <th key={column.tcName}>
          <div>{column.tcName}</div>
          <div className="en">{column.enName}</div>
        </th>
      ))}
      <th>
        {/* {tagLabelList?.length > 0 && ( */}
        <>
          <div>{getStringByTheme('queueTagCall', LANG.TC, theme)}</div>
          <div className="en">{getStringByTheme('queueTagCall', LANG.EN, theme)}</div>
        </>
        {/* )} */}
      </th>
    </tr>
  );
};
